import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';

const Feature = props => {
  const { Label, children } = props;
  const [isOpen, setIsOpen] = useState(false);

  const icon = isOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down';
  return (
    <div className="card feature_single">
      <h4 className="header" onClick={() => setIsOpen(!isOpen)}>
        {Label} <i className={icon} />
      </h4>

      <Collapse isOpen={isOpen}>
        <div className="d-flex align-items-center flex">
          <ul className="ml-4">
            {children}
          </ul>
        </div>
      </Collapse>
    </div>
  );
};

Feature.propTypes = {
  Label: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default Feature;
