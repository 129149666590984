import React from 'react';
import UncontrolledCarousel from 'reactstrap/lib/UncontrolledCarousel';
import Layout from '../components/Layout';
import Feature from '../components/Feature';

import homeJson from '../data/home.json';

const items = homeJson.carousel.map((item, i) => ({
  src: item.imgSrc,
  altText: `Onsite Machine Example Work - Image ${i + 1}`,
  key: `carousel-${i}`,
}));

const HomePage = () => {
  return (
    <Layout pageWrapper="home">
      <div>
        <UncontrolledCarousel items={items} />
        <section className="home-feature feature">
          <div className="container">
            <div className="row no-gutters">
              <div className="d-flex col-md-4">
                <Feature Label="Field Machining">
                  <li>Milling</li>
                  <li>Drilling</li>
                  <li>Boring</li>
                  <li>Honing</li>
                  <li>Flange Facing</li>
                  <li>Tapping & Reaming</li>
                  <li>Journal & Shaft Machining</li>
                </Feature>
              </div>
              <div className="d-flex col-md-4 bg-dark-accent">
                <Feature Label="Millwright Service">
                  <li>Machinery Building</li>
                  <li>Industrial Facility Maintenance</li>
                  <li>Onsite Machine Repairs</li>
                  <li>Preventative Maintenance</li>
                  <li>Fabrication</li>
                </Feature>
              </div>
              <div className="d-flex col-md-4">
                <Feature Label="Shop Machining">
                  <li>Milling & Turning</li>
                  <li>
                    Lathe Machining
                  </li>
                </Feature>
              </div>
            </div>
          </div>
        </section>
      </div>

    </Layout>
  );
};

export default HomePage;
